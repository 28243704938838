export const RegisterServiceWorker = () => {
  if (window !== "undefined") {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("./serviceWorker.js")
        .then((reg) => {})
        .catch((err) => {});
    });
  }
};
