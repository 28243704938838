import * as React from "react";
import { StageSpinner } from "react-spinners-kit";

const DataLoader = ({ color, size }: { color?: string; size?: number }) => {
  return (
    <React.Fragment>
      <div>
        <StageSpinner
          color={color || "#1A202C"}
          size={size || 40}
          loading={true}
        />
      </div>
    </React.Fragment>
  );
};

export default DataLoader;
