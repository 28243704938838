import { useState, useEffect } from "react";
import Cookies from "js-cookie";

export function useCookie<T>(
  name: string,
  initialValue: T
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(() => {
    const cookieValue = Cookies.get(name);
    return cookieValue ? JSON.parse(cookieValue) : initialValue;
  });

  useEffect(() => {
    Cookies.set(name, JSON.stringify(value), { expires: 1 });
  }, [name, value]);

  return [value, setValue];
}
