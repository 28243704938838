import { defaultTo } from "ramda";
export const siteTitle = defaultTo(
  "Myles Business",
  process.env.REACT_APP_NAME
);

//auth
export const LOGIN = "/login";
export const UPDATEPASSWORD = "/updatepassword";
export const SUSPENDED = "/suspension";
export const SIGNUP = "/signup";
export const FORGOTPASSWORD = "/forgotpassword";
export const SUCCESS = "/success/:id";
export const ACCOUNT_OPTIONS = "/accounts";

export const MAIN_LAYOUT = "/app/";
export const LANDING = "/";
//for main pages
export const DASHBOARD = "/app";
export const ADMIN_MANAGEMENT = "/app/admin";
export const SETTINGS = "/app/settings";
export const RIDERS = "/app/riders";
export const ORDERS = "/app/orders";
export const ORDER_DETAILS = "/app/order/:id";
// for 404
export const NOT_FOUND = "/not-found";
