import { Fragment } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import Auth, { BASE_URL } from "../cookie.config";
import { useAuthProvider } from "../context";
import { toast } from "sonner";

const ClientApollo = ({ children }) => {
  const [{ signOut }] = useAuthProvider();
  const httpLink = new HttpLink({
    uri: BASE_URL,
  });

  const authLink = new ApolloLink((operation, forward) => {
    let authorization = null;
    let auth = Auth.getCipher();
    let customHeaders = {};
    if (auth) {
      authorization = JSON.parse(auth);
      customHeaders.Authorization = `Bearer ${authorization}`;
    }

    operation.setContext({
      headers: {
        ...customHeaders,
      },
    });
    return forward(operation);
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      if (graphQLErrors?.length > 0) {
        if (graphQLErrors[0].message === "AuthorizationExpired") {
          signOut();
          return toast.error("Oops... Your session expired");
        }
      }
    }

    if (networkError) {
      return toast.error(networkError?.message);
    }
  });

  let link = from([authLink, errorLink, httpLink]);

  // Define custom merge function for InMemoryCache
  const cache = new InMemoryCache({
    typePolicies: {
      BusinessAdmin: {
        fields: {
          businessUsers: {
            merge(existing = [], incoming) {
              // Return merged array of existing and incoming objects
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  });

  const client = new ApolloClient({
    link: link,
    cache: cache,
  });

  return (
    <Fragment>
      <ApolloProvider client={client}> {children}</ApolloProvider>
    </Fragment>
  );
};

export default ClientApollo;
