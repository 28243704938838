import { gql } from "@apollo/client";

export const GET_CURRENT_ADMIN = gql`
  query {
    currentBusinessAdmin {
      createdAt
      email
      fullName
      profilePhoto
      id
      phone
      businessUsers {
        business {
          about
          address
          bookCommission
          commission
          startTime
          endTime
          openedAt
          closedAt
          logo
          type
          contactEmail
          contactName
          contactPhone
          createdAt
          businessSuspensionActivityId
          socialMediaLinks
          name
          parentBusinessID
        }
        businessAdminId
        businessId
        createdAt
        createdById
        driverId
        id
        type
      }
      updatedAt
      setupAt
    }
  }
`;
