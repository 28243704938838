import * as React from "react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { BusinessUser, CurrentUser } from "../../shared/types/pages/auth";
import { DataLoader } from "../../shared/loaders";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_ADMIN } from "../../services/graphql/auth/query";
import { ACCOUNT_OPTIONS, SUSPENDED } from "../../navigation/constants";
import { toast } from "sonner";
import { useCookie } from "../../components/hooks";
import { CURRENT_BRANCH_IDENTIFIER } from "../../components/contants";

type BusinessAdminInfo = Exclude<CurrentUser, "businessUsers">;

type CurrentBusiness = {
  selectedBranch: BusinessUser | null;
  allBranches: BusinessUser[];
  businessAdminInfo: BusinessAdminInfo;
  isUserAdmin: boolean;

  handleSelectBranch: (business: BusinessUser | null) => void;
};
export const CurrentUserContext = React.createContext<CurrentBusiness>(
  {} as CurrentBusiness
);

interface Props {
  children: React.ReactNode;
}

const CurrentUserComponent: FC<Props> = ({ children }) => {
  const [selectedBranch, setSelectedBranch] = useCookie<BusinessUser | null>(
    CURRENT_BRANCH_IDENTIFIER,
    null
  );
  const [businessAdminInfo, setBusinessAdminInfo] =
    React.useState<BusinessAdminInfo | null>(null);

  const { push } = useHistory();
  const { data, loading } = useQuery<
    {
      currentBusinessAdmin: CurrentUser;
    },
    any
  >(GET_CURRENT_ADMIN, {
    onError: () => {
      // toast.error("Unauthorized");
      // push(LOGIN);
    },
    onCompleted: (response) => {
      if (
        response?.currentBusinessAdmin?.businessUsers[0].business
          ?.businessSuspensionActivityId
      ) {
        toast.error("Business has been suspended");
        return push(SUSPENDED);
      }
      const { businessUsers, ...rest } = response?.currentBusinessAdmin;
      setBusinessAdminInfo?.(rest as BusinessAdminInfo);

      if (
        response?.currentBusinessAdmin?.businessUsers?.length > 1 &&
        selectedBranch === null
      ) {
        return push(ACCOUNT_OPTIONS);
      }

      if (response?.currentBusinessAdmin?.businessUsers?.length === 1) {
        return setSelectedBranch?.(
          response?.currentBusinessAdmin?.businessUsers?.[0] as BusinessUser
        );
      }
    },
    // fetchPolicy: "network-only",
  });

  const handleSelectBranch = React.useCallback(
    (business: BusinessUser | null) => {
      setSelectedBranch?.(business);
    },
    [setSelectedBranch]
  );

  return (
    <>
      {loading ? (
        <React.Fragment>
          <div
            style={{
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DataLoader />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CurrentUserContext.Provider
            value={{
              selectedBranch: selectedBranch as BusinessUser,
              allBranches:
                data?.currentBusinessAdmin?.businessUsers ||
                ({} as BusinessUser[]),
              businessAdminInfo: businessAdminInfo as BusinessAdminInfo,
              isUserAdmin:
                selectedBranch?.business?.contactEmail ===
                businessAdminInfo?.email,
              handleSelectBranch,
            }}
          >
            {children}
          </CurrentUserContext.Provider>
        </React.Fragment>
      )}
    </>
  );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
export default CurrentUserComponent;
