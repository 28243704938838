import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useSessionStorage } from "../../components/hooks";

const InstallBanner = () => {
  const [showBanner, setShowBanner] = useSessionStorage<boolean>(
    "myles-business-pwa",
    true
  );
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: { preventDefault: () => void }) => {
      // Prevent the default browser installation prompt
      e.preventDefault();
      // Store the event for later use
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const installPWA = () => {
    if (deferredPrompt) {
      // Show your custom "Install" button
      deferredPrompt.prompt();

      // Wait for the user to make a choice
      deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === "accepted") {
        } else {
        }

        // Clear the deferredPrompt variable
        setDeferredPrompt(null);
        // Hide the banner after installation
        setShowBanner(false);
      });
    } else {
      alert(
        "To add this app to your home screen, tap the Share button (square with an arrow), and then select 'Add to Home Screen'."
      );
    }
  };

  return (
    <>
      {showBanner ? (
        <div
          id="pwa"
          className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8"
        >
          <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-black px-6 py-2.5 shadow-xl sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <button
              onClick={installPWA}
              type="button"
              className="focus:outline-none text-sm leading-6 text-white"
            >
              <div>
                <strong className="font-semibold">Myles Business</strong>
                <svg
                  viewBox="0 0 2 2"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                  aria-hidden="true"
                >
                  <circle cx={1} cy={1} r={1} />
                </svg>
                Install this app on your home screen for quick and easy
                access&nbsp;
                <span aria-hidden="true">&rarr;</span>
              </div>
            </button>
            <button
              onClick={() => setShowBanner(false)}
              type="button"
              className="-m-1.5 flex-none p-1.5"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InstallBanner;
