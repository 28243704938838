import { FC, Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArchiveIcon,
  ChevronUpIcon,
  HomeIcon,
  ShoppingCartIcon,
  TruckIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useHistory, useLocation } from "react-router-dom";
import { useMobileContext } from "../../../services/context/mobile-navigation";
import { classNames } from "../../../components/classnames";
import { useCurrentUser } from "../../../services/context/currentUser";

export const mobileNavigation = [
  { name: "Home", href: "/app", icon: HomeIcon },
  { name: "Admins", href: "/app/admin", icon: ArchiveIcon },
  { name: "Riders", href: "/app/riders", icon: TruckIcon },
  { name: "Orders", href: "/app/orders", icon: ShoppingCartIcon },
];

const settingsNav = [
  {
    id: 1,
    name: "Account",
    href: "/app/settings?type=account",
    initial: "A",
  },
  {
    id: 2,
    name: "Business",
    href: "/app/settings?type=business",
    initial: "B",
  },
  {
    id: 3,
    name: "Branches",
    href: "/app/settings?type=branches",
    initial: "B",
  },
  { id: 4, name: "Funds", href: "/app/settings?type=funds", initial: "F" },
  {
    id: 5,
    name: "Pricing",
    href: "/app/settings?type=billing",
    initial: "P",
  },
];

type DashoardHeaderProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  setLogout: (value: boolean) => void;
  setUpdatePassword: (value: boolean) => void;
};

export const DashoardHeader: FC<DashoardHeaderProps> = ({
  sidebarOpen,
  setSidebarOpen,
  setLogout,
  setUpdatePassword,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { activeMenuUrl, handleSelectMenu } = useMobileContext();

  const currentUser = useCurrentUser();

  return (
    <>
      <nav className="bg-black lg:hidden">
        <>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-full flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex w-full grow flex-col gap-y-5 overflow-y-auto bg-black px-6 pb-4 ring-0 ring-white/10">
                      <button
                        type="button"
                        onClick={() => {
                          setSidebarOpen(false);
                          push("/app/");
                        }}
                        className="focus:outline-none flex h-16 shrink-0 items-center"
                      >
                        <span className="text-3xl font-bold text-white">
                          Myles Business
                        </span>
                      </button>
                      <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                          <li>
                            <ul className="-mx-2 space-y-1">
                              {mobileNavigation.map((item) => (
                                <li
                                  role="button"
                                  onClick={() => {
                                    setSidebarOpen(false);
                                    handleSelectMenu(item.href);
                                  }}
                                  key={item.name}
                                  className="cursor-pointer"
                                >
                                  <span
                                    className={classNames(
                                      [item.href.charAt(5)].includes(
                                        pathname.charAt(5)
                                      )
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-200">
                              Settings
                            </div>
                            <ul className="-mx-2 mt-2 space-y-1">
                              {settingsNav.map((setting) => (
                                <li
                                  key={setting.name}
                                  role="button"
                                  onClick={() => {
                                    setSidebarOpen(false);
                                    handleSelectMenu(setting.href);
                                  }}
                                  className="cursor-pointer"
                                >
                                  <span
                                    className={classNames(
                                      activeMenuUrl === setting.href
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    )}
                                  >
                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                      {setting.initial}
                                    </span>
                                    <span className="truncate">
                                      {setting.name}
                                    </span>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li className="mt-auto">
                            <Menu as="div" className="relative flex-shrink-0">
                              {({ open }) => (
                                <>
                                  <div>
                                    <Menu.Button className="focus-within:outline-none focus:outline-none flex w-full rounded-full text-sm focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-gray-900">
                                      <span className="sr-only">
                                        Open user menu
                                      </span>
                                      <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center gap-3">
                                          <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                                            <svg
                                              className="h-full w-full text-gray-300"
                                              fill="currentColor"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                          </span>
                                          <div className="flex flex-col items-start">
                                            <span className="text-white">
                                              {
                                                currentUser?.businessAdminInfo
                                                  ?.fullName
                                              }
                                            </span>
                                            <span className="text-gray-700">
                                              {
                                                currentUser?.businessAdminInfo
                                                  ?.email
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <ChevronUpIcon className="h-5 w-5 text-white" />
                                      </div>
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="right- focus:outline-none absolute -top-24 z-50 mt-2 w-48 origin-top-left rounded-md bg-white py-1 shadow-lg ring-0 ring-black ring-opacity-5 focus:ring-0"
                                    >
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setSidebarOpen(false);
                                              setUpdatePassword(true);
                                            }}
                                            className={classNames(
                                              active ? "bg-gray-100" : "",
                                              "block w-full px-4 py-2 text-left text-sm text-gray-700"
                                            )}
                                          >
                                            Update Password
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setSidebarOpen(false);
                                              setLogout(true);
                                            }}
                                            className={classNames(
                                              active ? "bg-gray-100" : "",
                                              " flex w-full items-start px-4 py-2 text-sm text-red-700"
                                            )}
                                          >
                                            Logout
                                          </button>
                                        )}
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      </nav>
    </>
  );
};
