import * as React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CenterLoader } from "../shared/loaders";
import {
  LOGIN,
  MAIN_LAYOUT,
  NOT_FOUND,
  FORGOTPASSWORD,
  LANDING,
  SIGNUP,
  SUSPENDED,
  SUCCESS,
  UPDATEPASSWORD,
  ACCOUNT_OPTIONS,
} from "./constants";
import CurrentUser from "../services/context/currentUser";
import HandleNotFound from "./handle-not-found";
import { MobileNavigationProvider } from "../services/context/mobile-navigation";

const Landing = React.lazy(() => import("../pages/landing"));
const LoginPage = React.lazy(() => import("../pages/authentication/login"));
const UpdatePassword = React.lazy(
  () => import("../pages/authentication/setnewpassword")
);
const SuspendedPage = React.lazy(
  () => import("../pages/authentication/suspension")
);
const SignUpPage = React.lazy(() => import("../pages/authentication/signup"));
const ForgotPassword = React.lazy(
  () => import("../pages/authentication/forgotpassword")
);
const SuccessPage = React.lazy(() => import("../pages/success"));
const AccountSelection = React.lazy(
  () => import("../pages/authentication/accounts")
);
const Layout = React.lazy(() => import("../shared/layout"));

const RouterConfig: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <MobileNavigationProvider>
          <React.Suspense fallback={CenterLoader()}>
            <Switch>
              <CurrentUser>
                <Route exact={true} component={Landing} path={LANDING} />
                <Route exact={true} component={SignUpPage} path={SIGNUP} />
                <Route exact={true} component={LoginPage} path={LOGIN} />
                <Route
                  exact={true}
                  component={UpdatePassword}
                  path={UPDATEPASSWORD}
                />
                <Route
                  exact={true}
                  component={AccountSelection}
                  path={ACCOUNT_OPTIONS}
                />
                <Route exact={true} component={SuccessPage} path={SUCCESS} />
                <Route
                  exact={true}
                  component={SuspendedPage}
                  path={SUSPENDED}
                />
                <Route
                  exact={true}
                  component={ForgotPassword}
                  path={FORGOTPASSWORD}
                />
                {/* handle 404s */}
                <Route
                  exact={true}
                  component={HandleNotFound}
                  path={NOT_FOUND}
                />
                <Route component={Layout} path={MAIN_LAYOUT} />
              </CurrentUser>
            </Switch>
          </React.Suspense>
        </MobileNavigationProvider>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default RouterConfig;
