import { Toaster } from "sonner";
import AppNavigator from "./services/context";
import InstallBanner from "./shared/install-banner";

const App = () => {
  return (
    <div>
      <Toaster richColors position="top-right" expand />
      <AppNavigator />
      <InstallBanner />
    </div>
  );
};

export default App;
