import * as React from "react";
import { SetValue, useSessionStorage } from "../../components/hooks";
import { useHistory } from "react-router-dom";
import { mobileNavigation } from "../../shared/layout/components/mobilemenu";

type ReturnValue = {
  activeMenuUrl: string;
  handleSelectMenu: (url: string) => void;
  setValue: SetValue<string>;
};

type Props = {
  children: React.ReactNode;
};

const MobileContext = React.createContext({} as ReturnValue);

export const MobileNavigationProvider: React.FC<Props> = ({ children }) => {
  const [value, setValue] = useSessionStorage<string>(
    "mobile-navigation",
    mobileNavigation?.[0]?.href
  );
  const history = useHistory();

  const handleSelectMenu = React.useCallback(
    (url: string) => {
      setValue(url);
      history.push(url);
    },
    [setValue, history]
  );

  return (
    <MobileContext.Provider
      value={{
        activeMenuUrl: value,
        handleSelectMenu,
        setValue,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const useMobileContext = () => React.useContext(MobileContext);
