import Cookies from "js-cookie";
import { CURRENT_BRANCH_IDENTIFIER } from "../components/contants";

// export const BASE_URL = "http://localhost:5001/graphql";
export const BASE_URL = process.env.REACT_APP_SERVER_URL + "/graphql";

class Auth {
  getCipher(): string | null {
    return Cookies.get("dropoff-business-cipher") || null;
  }

  setCipher(token: string): void {
    Cookies.set("dropoff-business-cipher", token, { expires: 1 }); // expire 24 hours
  }

  clearCipher(): void {
    Cookies.remove("dropoff-business-cipher");
    Cookies.remove(CURRENT_BRANCH_IDENTIFIER);
  }
}

const auth = new Auth();

export default auth;
